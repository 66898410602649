<template>
  <div
    :style="
      theme.isDarkBackground ? `background-color: ${theme.backgroundColor}` : ''
    "
    :class="theme.isDarkBackground ? 'dark' : ''"
  >
    <div id="header" class="header container topLineBeforeScroll">
      <div class="logo">
        <router-link
          v-if="theme.name === 'SAT'"
          :to="{ name: isLogin ? 'SATPractices' : 'Login' }"
          @click="closeClick"
        >
          <template>
            <h2>
              SAT
            </h2>
            <span class="toefl-r">&reg;</span>
            <h2>Mocks</h2>
          </template>
        </router-link>
        <template v-else>
          <router-link
            :to="{ name: isLogin ? 'SATPractices' : 'Home' }"
            @click="closeClick"
          >
            <div class="logo-img" v-if="theme.logo">
              <img :src="theme.logo" alt="" />
            </div>
            <h2 class="text-success" v-else>
              {{ theme.name }}
            </h2>
          </router-link>
        </template>
        <div id="menu" :class="{ active: isActive }">
          <div class="d-flex">
            <div class="menu" v-for="(menu, index) in menuList" :key="index">
              <div>
                <el-badge
                  value="New"
                  v-show="theme.name === 'SAT' && menu.route.name === 'Plans'"
                >
                  <router-link style="text-decoration: none" :to="menu.route">
                    <div
                      :class="{ menuPadding: !isLogin }"
                      class="onHover pa-0 pr-1 headerMenuItemText"
                    >
                      {{ $t(menu.i18nKey) }}
                    </div>
                  </router-link>
                </el-badge>
                <router-link
                  style="text-decoration: none"
                  :to="menu.route"
                  v-show="menu.route.name !== 'Plans'"
                >
                  <div
                    :class="{ menuPadding: !isLogin }"
                    class="onHover pa-0 pr-1 headerMenuItemText"
                  >
                    {{ $t(menu.i18nKey) }}
                  </div>
                </router-link>
              </div>
            </div>
            <div class="ml-1" v-if="theme.linkArr && theme.linkArr.length > 0">
              <el-dropdown v-if="theme.linkArr.length > 1">
                <b class="el-dropdown-link">
                  {{ theme.name }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </b>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(menu, index) in theme.linkArr"
                    :key="index"
                  >
                    <a style="text-decoration: none" :href="menu.link">
                      <b>{{ $t(`header.${menu.name}`) }}</b>
                    </a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <a
                v-else
                style="text-decoration: none;font-size: 1rem;"
                :href="theme.linkArr[0].link"
              >
                <b>{{ theme.name }}</b>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="header-right">
        <div class="mr-4">
          <el-dropdown
            split-button
            type="primary"
            size="small"
            @command="handleLanguageChange"
            v-if="languageList1.length > 0"
            trigger="click"
          >
            Language: {{ lang.title }}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="languageList1[0]">{{
                languageList1[0].title
              }}</el-dropdown-item>
              <el-dropdown-item :command="languageList1[1]">{{
                languageList1[1].title
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="mr-2">
          <template v-if="isLogin">
            <el-dropdown trigger="click">
              <b class="el-dropdown-link">
                <span v-if="getProfile.first_name"
                  >Hi, {{ getProfile.first_name }}</span
                >
                <span v-else>Hi, {{ getProfile.username }}</span>
                <span v-if="getIsPro" class="proIcon">
                  <i class="fas fa-crown" />
                </span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </b>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(elem, index) in loginList"
                  :key="index"
                >
                  <router-link
                    :to="{ name: elem.routeName }"
                    class="menuItem"
                    style="display:block"
                  >
                    <span style="display:inline-block;width: 1.2rem;"
                      ><i :class="elem.icon"></i
                    ></span>
                    {{ $t(elem.title) }}
                  </router-link>
                </el-dropdown-item>
                <template v-if="isRoleAdmin()">
                  <el-dropdown-item v-for="elem in adminList" :key="elem.title">
                    <router-link
                      :to="{ name: elem.routeName }"
                      class="menuItem"
                      style="display:block"
                    >
                      <span style="display:inline-block;width: 1.2rem;">
                        <i :class="elem.icon"></i>
                      </span>
                      {{ $t(elem.title).toUpperCase() }}
                    </router-link>
                  </el-dropdown-item>
                </template>
                <template v-if="isRoleCompanyAdmin()">
                  <el-dropdown-item
                    v-for="elem in companyList"
                    :key="elem.title"
                  >
                    <router-link
                      :to="{ name: elem.routeName }"
                      class="menuItem"
                      style="display:block"
                    >
                      <span style="display:inline-block;width: 1.2rem;">
                        <i :class="elem.icon"></i>
                      </span>
                      {{ $t(elem.title).toUpperCase() }}
                    </router-link>
                  </el-dropdown-item>
                </template>
                <template v-if="isRoleTeacher()">
                  <el-dropdown-item
                    v-for="elem in teacherList"
                    :key="elem.title"
                  >
                    <router-link
                      :to="{ name: elem.routeName }"
                      class="menuItem"
                      style="display:block"
                    >
                      <span style="display:inline-block;width: 1.2rem;">
                        <i :class="elem.icon"></i>
                      </span>
                      {{ $t(elem.title).toUpperCase() }}
                    </router-link>
                  </el-dropdown-item>
                </template>
                <el-dropdown-item>
                  <div @click="handleLogout" class="menuItem">
                    <span style="display:inline-block;width: 1.2rem;">
                      <i class="fas fa-sign-out-alt"></i>
                    </span>
                    {{ $t("header.logout") }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-else>
            <el-button
              size="small"
              type="primary"
              @click="goToRoute('Login')"
              >{{ $t("header.login") }}</el-button
            >
          </template>
        </div>
      </div>
    </div>
    <!-- Header Second -->
    <div
      :class="[
        'navWrapperBefore',
        onTop ? 'menuBeforeScroll' : 'menuAfterScroll'
      ]"
      :style="
        theme.isDarkBackground
          ? `background-color: ${theme.backgroundColor}`
          : ''
      "
    >
      <div class="logo">
        <router-link
          v-if="theme.name === 'SAT'"
          :to="{ name: 'Home' }"
          @click="closeClick"
        >
          <h2>
            SAT
          </h2>
          <span class="toefl-r">&reg;</span>
          <h2>Mocks</h2>
        </router-link>
        <template v-else>
          <div class="logo-img" v-if="theme.logo">
            <img :src="theme.logo" alt="" />
          </div>
          <h2 class="text-success" v-else>
            {{ theme.name }}
          </h2>
        </template>
      </div>
      <div id="toggle" @click="toggleClick" class="mr-4">
        <div class="span" id="top" :class="{ active: isActive }"></div>
        <div class="span" id="middle" :class="{ active: isActive }"></div>
        <div class="span" id="bottom" :class="{ active: isActive }"></div>
      </div>
      <el-drawer
        :visible.sync="isActive"
        direction="rtl"
        :modal="false"
        size="100%"
      >
        <h4 slot="title">
          <template v-if="isLogin">
            <span v-if="getProfile.first_name">
              Hi, {{ getProfile.first_name }}
            </span>
            <span v-else>Hi, {{ getProfile.username }}</span>
            <span v-if="getIsPro" class="proIcon">
              <i class="fas fa-crown" />
            </span>
          </template>
        </h4>
        <div class="accordion listParent" id="accordionExample">
          <div class="card">
            <div class="card-header" id="headingOne" v-if="isLogin">
              <h2
                class="mb-0"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-controls="collapseOne"
                @click="() => toggleCollapse('collapseOne')"
              >
                <div class="collapse-item">
                  <div>
                    <span style="display:inline-block;width:25px">
                      <i class="fas fa-user"></i>
                    </span>
                    Account
                  </div>
                  <small
                    v-if="isShow.collapseOne"
                    key="up"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-controls="collapseOne"
                  >
                    <i class="fas fa-chevron-up" />
                  </small>
                  <small
                    v-else
                    key="down"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-controls="collapseOne"
                  >
                    <i class="fas fa-chevron-down" />
                  </small>
                </div>
              </h2>
            </div>

            <div
              class="card-header"
              id="headingOne"
              v-if="!isLogin"
              @click="goToRoute('Login')"
            >
              <h2
                class="mb-0"
                data-toggle="collapse"
                data-target="#collapseZero"
                aria-expanded="true"
                aria-controls="collapseZero"
              >
                <div class="collapse-item">
                  <div>
                    <span style="display:inline-block;width:25px">
                      <i class="fas fa-sign-in-alt"></i>
                    </span>
                    <span>{{ $t("header.login") }}</span>
                  </div>
                </div>
              </h2>
            </div>
            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="card-body" v-if="isLogin">
                <div
                  class="list onHover"
                  v-for="(elem, index) in loginList"
                  :key="index"
                  @click="closeClick"
                >
                  <router-link class="listText" :to="{ name: elem.routeName }">
                    <span style="display:inline-block;width:25px">
                      <i :class="elem.icon"></i>
                    </span>
                    {{ $t(elem.title) }}
                  </router-link>
                </div>
                <template v-if="isRoleAdmin()">
                  <div
                    v-for="elem in adminList"
                    :key="elem.title"
                    class="list onHover"
                    @click="closeClick"
                  >
                    <router-link
                      :to="{ name: elem.routeName }"
                      class="listText"
                    >
                      <span style="display:inline-block;width: 25px;">
                        <i :class="elem.icon"></i>
                      </span>
                      {{ $t(elem.title).toUpperCase() }}
                    </router-link>
                  </div>
                </template>
                <template v-if="isRoleCompanyAdmin()">
                  <div
                    v-for="elem in companyList"
                    :key="elem.title"
                    class="list onHover"
                    @click="closeClick"
                  >
                    <router-link
                      :to="{ name: elem.routeName }"
                      class="listText"
                    >
                      <span style="display:inline-block;width: 25px;">
                        <i :class="elem.icon"></i>
                      </span>
                      {{ $t(elem.title).toUpperCase() }}
                    </router-link>
                  </div>
                </template>
                <template v-if="isRoleTeacher()">
                  <div
                    v-for="elem in teacherList"
                    :key="elem.title"
                    class="list onHover"
                    @click="closeClick"
                  >
                    <router-link
                      :to="{ name: elem.routeName }"
                      class="listText"
                    >
                      <span style="display:inline-block;width: 25px;">
                        <i :class="elem.icon"></i>
                      </span>
                      {{ $t(elem.title).toUpperCase() }}
                    </router-link>
                  </div>
                </template>
                <div class="list onHover">
                  <span class="listText" @click="handleLogout">
                    <span style="display:inline-block;width:25px">
                      <i class="fas fa-sign-out-alt"></i>
                    </span>
                    {{ $t("header.logout") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2
                class="mb-0"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
                @click="() => toggleCollapse('collapseTwo')"
              >
                <div class="collapse-item">
                  <div>
                    <span style="display:inline-block;width:25px">
                      <i class="fas fa-book"></i>
                    </span>
                    SAT
                  </div>
                  <small
                    v-if="isShow.collapseTwo"
                    key="up"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    <i class="fas fa-chevron-up" />
                  </small>
                  <small
                    v-else
                    key="down"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    <i class="fas fa-chevron-down" />
                  </small>
                </div>
              </h2>
            </div>
            <div
              id="collapseTwo"
              class="collapse show"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div
                  class="list onHover"
                  v-for="(menu, index) in menuList"
                  :key="index"
                  @click="closeClick"
                >
                  <router-link
                    style="text-decoration: none"
                    :to="menu.route"
                    v-show="theme.name === 'SAT' && menu.route.name === 'Plans'"
                  >
                    <div
                      :class="{ menuPadding: !isLogin }"
                      class="onHover pa-0 pr-1"
                    >
                      <span style="display:inline-block;width:25px">
                        <i :class="menu.icon"></i>
                        <!-- <i class="fas fa-book"></i> -->
                      </span>
                      {{ $t(`${menu.i18nKey}`) }}
                    </div>
                  </router-link>
                  <router-link
                    style="text-decoration: none"
                    :to="menu.route"
                    v-show="menu.route.name !== 'Plans'"
                  >
                    <div
                      :class="{ menuPadding: !isLogin }"
                      class="onHover pa-0 pr-1"
                    >
                      <span style="display:inline-block;width:25px">
                        <i :class="menu.icon"></i>
                      </span>
                      {{ $t(`${menu.i18nKey}`) }}
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2
                class="mb-0"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
                @click="() => toggleCollapse('collapseThree')"
              >
                <div class="collapse-item">
                  <div>
                    <span style="display:inline-block;width:25px">
                      <i class="fas fa-globe"></i>
                    </span>
                    Language:
                    <span> {{ lang.title }}</span>
                  </div>
                  <small
                    v-if="isShow.collapseThree"
                    key="up"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <i class="fas fa-chevron-up" />
                  </small>
                  <small
                    v-else
                    key="down"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <i class="fas fa-chevron-down" />
                  </small>
                </div>
              </h2>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div
                  class="list onHover"
                  v-for="(language, index) in languageList1"
                  :key="index"
                >
                  <span
                    class="listText"
                    @click="handleLanguageChange(language)"
                    >{{ language.title }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import userApi from "@/apis/user";
import roleMixin from "@/mixins/role";

export default {
  components: {},

  mixins: [roleMixin],

  props: ["theme"],
  data() {
    return {
      menuIndex: "0",
      menuList: [
        {
          icon: "el-icon-menu",
          route: {
            name: "SATPractices"
          },
          i18nKey: "courses.practice",
          isNew: false
        },
        {
          icon: "el-icon-s-data",
          route: {
            name: "SATResults",
            query: {
              type: "composePractices"
            }
          },
          i18nKey: "sat.SAT_Results",
          isNew: false
        },
        {
          icon: "el-icon-s-home",
          route: {
            name: "MyClasses"
          },
          i18nKey: "headerTitle.Classes",
          isNew: false
        },
        // {
        //   icon: "el-icon-s-home",
        //   route: {
        //     name: "Instructors"
        //   },
        //   i18nKey: "headerTitle.Instructors",
        //   isNew: false
        // },
        // {
        //   icon: "el-icon-s-home",
        //   route: {
        //     name: "classes"
        //   },
        //   i18nKey: "headerTitle.ShowClasses",
        //   isNew: false
        // },
        {
          icon: "fas fa-crown",
          route: {
            name: "Plans"
          },
          i18nKey: "header.plans",
          isNew: true
        }
        // {
        //   icon: "fas fa-dumbbell",
        //   route: {
        //     name: "Flashcards"
        //   },
        //   i18nKey: "header.Flashcards"
        // }
      ],
      loginList: [
        // {
        //   icon: "fas fa-donate",
        //   title: "header.balance",
        //   routeName: "Balance"
        // },
        {
          icon: "fas fa-user",
          title: "header.profile",
          routeName: "UserProfile"
        }
      ],
      adminList: [
        {
          icon: "fas fa-building",
          title: "header.admin",
          routeName: "SatManagement"
        }
      ],
      companyList: [
        {
          icon: "fas fa-users",
          title: "header.company",
          routeName: "Company"
        }
      ],
      teacherList: [
        {
          icon: "fas fa-chalkboard-teacher",
          title: "header.teacher",
          routeName: "Teacher"
        }
      ],
      languageList: [
        {
          value: "ZH-CN",
          title: "简体中文"
        },
        {
          value: "ZH-TW",
          title: "繁體中文"
        },
        {
          value: "EN-US",
          title: "English"
        }
      ],
      languageList1: [
        {
          value: "ZH-CN",
          title: "简体中文"
        },
        {
          value: "ZH-TW",
          title: "繁體中文"
        },
        {
          value: "EN-US",
          title: "English"
        }
      ],
      languageList2: [
        {
          value: "ZH-CN",
          title: "简体中文"
        },
        {
          value: "ZH-TW",
          title: "繁體中文"
        },
        {
          value: "EN-US",
          title: "English"
        }
      ],
      isActive: false,
      onTop: true,
      facebookQRcode: require("@/assets/qrcode/ivywayfacebook_QR.webp"),
      isShow: {
        collapseOne: false,
        collapseTwo: true,
        collapseThree: false
      }
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    isLogin() {
      return this.token !== "";
    }
  },
  watch: {
    getProfile(val) {
      if (val.lang === null) {
        this.autoLang();
      } else {
        this.changeLangDefault();
      }
    }
  },
  async mounted() {
    this.updateLangOrder(this.lang.title);
    if (this.isLogin && !this.getProfile.length) {
      await this.$store.dispatch("user/getProfile");
    } else {
      if (this.theme.name === "Ivy-Way") {
        // if (!this.$route.query.token) {
        //   window.location.href = "https://ivy-way.com/home?sat=sat";
        // }
      }
    }
    if (JSON.stringify(this.getProfile) === "{}") {
      this.autoLang();
    } else {
      if (this.getProfile.lang === null) {
        this.autoLang();
      } else {
        this.changeLangDefault();
      }
    }
    this.startObserveWindowWidth();
  },

  methods: {
    ...mapActions("user", ["changeLang", "logout"]),
    goToRoute(route) {
      this.closeClick();
      if (this.$route.name !== route) {
        this.$router.push({
          name: route
        });
      }
    },
    closeClick() {
      document.documentElement.style.overflow = "overlay";
      this.isActive = false;
      this.showLanguages = false;
      // 只要每次關閉 list 就重置所有 collapse 為隱藏狀態
      if (document.getElementById("collapseOne")) {
        document.getElementById("collapseOne").classList.remove("show");
      }
      if (document.getElementById("collapseTwo")) {
        document.getElementById("collapseTwo").classList.add("show");
      }
      if (document.getElementById("collapseThree")) {
        document.getElementById("collapseThree").classList.remove("show");
      }
    },
    toggleClick() {
      const currOverflow = document.documentElement.style.overflow;
      if (currOverflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
      this.isActive = !this.isActive;
    },
    async handleLanguageChange(language) {
      this.closeClick();
      this.updateLangOrder(language.value);
      let lang = "";
      switch (language.value) {
        case "ZH-TW":
          this.$i18n.locale = "tw";
          lang = "tw";
          break;
        case "EN-US":
          this.$i18n.locale = "en";
          lang = "en";
          break;
        case "ZH-CN":
          this.$i18n.locale = "cn";
          lang = "cn";
          break;
      }
      // if (this.isLogin && !this.getProfile.length) {
      //   await userApi.saveLang({
      //     lang: lang
      //   });
      // }
      this.changeLang(language);
    },
    async autoLang() {
      this.currCountryCode = "EN";
      this.changeLangDefault();
    },
    changeLangDefault() {
      let language = {};
      if (this.currCountryCode === "TW") {
        this.$i18n.locale = "tw";
        language = this.languageList.find(x => x.value === "ZH-TW");
      } else if (this.currCountryCode === "CN") {
        this.$i18n.locale = "cn";
        language = this.languageList.find(x => x.value === "ZH-CN");
      } else {
        this.$i18n.locale = "en";
        language = this.languageList.find(x => x.value === "EN-US");
      }
      this.updateLangOrder(language.value);
      this.changeLang(language);
    },
    async handleLogout() {
      this.closeClick();
      await this.logout();
      await this.$message({
        message: this.$t("message.logout_success"),
        type: "success"
      });
      if (this.$route.name === "Login") {
        return;
      }
      this.$router.push({
        name: "Login"
      });
    },
    updateLangOrder(curLang) {
      this.languageList1 = [];
      this.languageList2 = [];
      const currIndex = this.languageList.findIndex(x => x.value === curLang);
      if (currIndex === 0) {
        this.languageList1.push(this.languageList[1]);
        this.languageList1.push(this.languageList[2]);
        this.languageList2.push(this.languageList[1]);
        this.languageList2.push(this.languageList[0]);
        this.languageList2.push(this.languageList[2]);
      } else if (currIndex === 1) {
        this.languageList1.push(this.languageList[0]);
        this.languageList1.push(this.languageList[2]);
        this.languageList2 = this.languageList;
      } else {
        this.languageList1.push(this.languageList[0]);
        this.languageList1.push(this.languageList[1]);
        this.languageList2.push(this.languageList[0]);
        this.languageList2.push(this.languageList[2]);
        this.languageList2.push(this.languageList[1]);
      }
    },
    closeMobileMenu(e) {
      if (e.target.screen.width > 992) {
        this.isActive = false;
      }
    },
    startObserveWindowWidth() {
      window.addEventListener("resize", this.closeMobileMenu);
    },
    toggleCollapse(collapse) {
      let workIsShow = { ...this.isShow };
      Object.keys(this.isShow).forEach(key => {
        workIsShow[key] = false;
      });
      this.isShow = {
        ...workIsShow,
        [collapse]: !this.isShow[collapse]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-badge__content.is-fixed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  line-height: normal;
  font-weight: 500;
  right: 0.1rem;
  background-color: orange;
  padding: 0 0.5rem;
}
@import "@/assets/scss/navbar.scss";
.proIcon {
  display: inline-block;
  margin: 0px 4px 4px 4px;
  color: orange;
}
.el-dropdown-link {
  font-weight: 700;
  position: relative;
  font-size: 1rem;
  color: var(--themeColor);
  white-space: nowrap;
  transition: all 0.15s linear;
}
.el-dropdown-link {
  font-weight: 700;
  position: relative;
  font-size: 1rem;
  color: var(--themeColor);
  white-space: nowrap;
  transition: all 0.15s linear;
}
.header {
  height: 60px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}
.headerMenuItemText {
  color: var(--themeColor);
}
.dark .headerMenuItemText {
  color: white;
}
.dark a {
  color: white !important;
}
::v-deep .dark .el-dropdown-link,
::v-deep .el-dropdown {
  color: white !important;
}
.logo {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.logo-img {
  height: 40px;
}
.logo-img img {
  height: 100%;
}
.logo h2 {
  font-weight: 700;
  display: inline-block;
  font-size: 28px;
  margin: 0;
}
.toefl-r {
  font-weight: 700;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
  // padding-top: 10px;
  margin-right: 5px;
}
#menu {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.menu {
  margin-left: 2rem;
}
.menu a {
  font-weight: 700;
  position: relative;
  font-size: 1rem;
  color: #86909c !important;
  white-space: nowrap;
  transition: all 0.15s linear;
}
.header-right {
  display: flex;
  align-items: center;
}
.list {
  padding-top: 5px;
  height: 40px;
}

@media screen and (min-width: 992px) {
  .topLineBeforeScroll {
    display: flex;
  }
  .navWrapperBefore {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .topLineBeforeScroll {
    display: none;
  }
  .navWrapperBefore {
    display: block;
    border-bottom: 1px solid #ccc;
  }
  .logo {
    height: 60px;
    padding-left: 15px;
  }
  .card {
    border: 0px;
    font-size: 14px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .collapse-item {
    font-size: 16px;
    color: #616161;
    cursor: pointer;
  }

  .collapse-item:hover {
    opacity: 0.5;
  }
  .card-header {
    background-color: white;
    border: 0px;
  }

  .collapse-item {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .card-body {
    padding: 5px 5px 5px 35px;
  }
}
</style>
