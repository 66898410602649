import passwordApi from "@/apis/password";

const actions = {
  /*eslint-disable no-unused-vars */
  async sendRestLink({ commit }, payload) {
    /*eslint-enable */
    await passwordApi.sendRestLink(payload);
  },
  /*eslint-disable no-unused-vars */
  async reset({ commit }, payload) {
    /*eslint-enable */
    await passwordApi.reset(payload);
  }
};

export default {
  namespaced: true,
  actions
};
