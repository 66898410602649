import AIChat from "@/apis/ipGeo";

const state = {
  showAIChat: false,
  lastString: {
    answer: null,
    token: null,
    questionID: null
  },
  ip: "",
  geo: {
    iso_code: null,
    country: "United States",
    city: "Salt Lake City",
    timezone: "America/Denver"
  }
};

const getters = {
  getShowAIChat(state) {
    return state.showAIChat;
  },
  getGeoAfterCall(state) {
    return state.geo;
  }
};

const mutations = {
  setShowAIChat(state, data) {
    state.showAIChat = data;
  },
  setLastString(state, data) {
    state.lastString.answer = null;
    state.lastString.questionID = null;
    state.lastString = { ...data };
  },
  setGeo(state, data) {
    state.geo = { ...data };
  }
};

const actions = {
  async getShowAIChat({ commit }, payload) {
    let data = payload;
    commit("setShowAIChat", payload);
    return data;
  },
  async showAIChatAndNext({ commit }, payload) {
    commit("setShowAIChat", true);
    commit("setLastString", payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
