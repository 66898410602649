import ipGeo from "@/apis/ipGeo";

const state = {
  ip: "",
  geo: {
    iso_code: null,
    country: "United States",
    city: "Salt Lake City",
    timezone: "America/Denver"
  }
};

const getters = {
  getIpAfterCall(state) {
    return state.ip;
  },
  getGeoAfterCall(state) {
    return state.geo;
  }
};

const mutations = {
  setIp(state, data) {
    state.ip = data;
  },
  setGeo(state, data) {
    state.geo = data;
  }
};

const actions = {
  async getGeoLocation({ commit }) {
    let data = {};
    try {
      data = await ipGeo.getGeoLocation();
    } catch (e) {
      data = {
        iso_code: null,
        country: "United States",
        city: "Salt Lake City",
        timezone: "America/Denver"
      };
    }
    commit("setGeo", data);
    return data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
