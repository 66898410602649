<template>
  <div>
    <div class="heading">
      <h1 v-if="heading === 'h1'">{{ content }}</h1>
      <h2 v-if="heading === 'h2'">{{ content }}</h2>
      <h3 v-if="heading === 'h3'">{{ content }}</h3>
      <h4 v-if="heading === 'h4'">{{ content }}</h4>
      <h5 v-if="heading === 'h5'">{{ content }}</h5>
      <h6 v-if="heading === 'h6'">{{ content }}</h6>
      <div class="ml-3">
        <slot name="button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: {
    heading: {
      type: String,
      default: "h3"
    },
    content: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.heading {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.heading h1,
.heading h2,
.heading h3,
.heading h4,
.heading h5,
.heading h6 {
  margin: 0;
}
</style>
