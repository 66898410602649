import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
export default {
  install(Vue, options) {
    Vue.prototype.getPdfShort = function(ele, title = "test") {
      let vm = this;
      let scale = 2;
      html2Canvas(document.querySelector(ele), {
        scale: scale,
        imageTimeout: 0,
        useCORS: true,
        allowTaint: true,
        dpi: window.devicePixelRatio * scale
      }).then(canvas => {
        // a4纸的尺寸[592.28,841.89]
        const A4_WIDTH = 592.28;
        const A4_HEIGHT = 841.89;
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageData = canvas.toDataURL("image/jpeg", 0.6);
        // 一页pdf html页面生成的canvas高度;
        let pageHeight = (contentWidth / A4_WIDTH) * A4_HEIGHT;
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight;
        // 页面偏移
        let position = 0;
        // a4纸的尺寸[592.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        let imgWidth = A4_WIDTH;
        let imgHeight = (A4_WIDTH / contentWidth) * contentHeight;
        // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
        let pdf = new JsPDF("", "pt", "a4");
        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度
        // 当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= A4_HEIGHT;
            // 避免添加空白页
            if (leftHeight > 0) {
              pdf.addPage();
            }
          }
        }
        vm.loading = false;
        pdf.save(title + ".pdf");
        // this.$router.go(0);
      });
    };
  }
};
