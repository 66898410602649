import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
import { Message, Loading, MessageBox } from "element-ui";
export default {
  install(Vue, options) {
    Vue.prototype.getPdf = async function(ele, title = "test") {
      let loadingInstance;
      loadingInstance = await Loading.service({
        lock: true,
        background: "rgba(0, 0, 0, 0.8)",
        spinner: "el-icon-loading",
        text: "Processing... Please wait."
      });
      let vm = this;
      let scale = 2;
      var canvasList = document.getElementsByClassName(ele);
      console.log(canvasList);
      let pdf = new JsPDF("", "pt", "a4");
      for (var i = 0; i < canvasList.length; i++) {
        console.log(canvasList[i]);
        await html2Canvas(canvasList[i], {
          scale: scale,
          imageTimeout: 0,
          useCORS: true,
          allowTaint: true,
          dpi: window.devicePixelRatio * scale
        }).then(async canvas => {
          // a4纸的尺寸[592.28,841.89]
          const A4_WIDTH = 592.28;
          const A4_HEIGHT = 841.89;
          let contentWidth = canvas.width;
          let contentHeight = canvas.height;
          let pageData = canvas.toDataURL("image/jpeg", 0.6);
          // 一页pdf html页面生成的canvas高度;
          let pageHeight = (contentWidth / A4_WIDTH) * A4_HEIGHT;
          // 未生成pdf的html页面高度
          let leftHeight = contentHeight;
          // 页面偏移
          let position = 0;
          // a4纸的尺寸[592.28,841.89]，html页面生成的canvas在pdf中图片的宽高
          let imgWidth = A4_WIDTH;
          let imgHeight = (A4_WIDTH / contentWidth) * contentHeight;
          // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
          // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度
          // 当内容未超过pdf一页显示的范围，无需分页
          console.log(pdf);
          if (leftHeight < pageHeight) {
            await pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              await pdf.addImage(
                pageData,
                "JPEG",
                0,
                position,
                imgWidth,
                imgHeight
              );
              leftHeight -= pageHeight;
              position -= A4_HEIGHT;
              // 避免添加空白页
              if (leftHeight > 0) {
                pdf.addPage();
              }
            }
          }
        });
        await pdf.addPage();
      }
      await pdf.save(title + ".pdf");
      await loadingInstance.close();
      this.$router.go(0);
    };
  }
};
