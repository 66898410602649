import ApiService from "@/common/api.service";

export default {
  getIncomes(payload) {
    return ApiService.query("admin/transactions-logs", {
      ...payload,
      category: "income",
      unit: "point"
    });
  },
  getExpenses(payload) {
    return ApiService.query("admin/transactions-logs", {
      ...payload,
      category: "expense",
      unit: "point"
    });
  },
  getTransactions(payload) {
    return ApiService.query("admin/transactions-logs", payload);
  },
  getUsersOfTransactions(payload) {
    return ApiService.query("admin/transactions-logs/users", payload);
  }
};
