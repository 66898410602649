import axios from "axios";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import JwtService from "@/common/jwt.service";
import baseDomainPath from "@/common/baseDomainPath";
import Theme from "@/common/theme";
import { Message, Loading, MessageBox } from "element-ui";

const interceptor = axios.create();

let loadingInstance;

interceptor.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (localStorage.id_token) {
      config.headers.Authorization = "Bearer " + localStorage.id_token;
    }

    config.headers["Accept-Language"] = i18n.locale;
    config.headers["Mock"] = "toefl";

    loadingInstance = Loading.service({
      lock: true,
      background: "transparent",
      spinner: "el-icon-loading"
    });

    return config;
  },
  function(error) {
    // Do something with request error
    loadingInstance.close();
    return Promise.reject(error);
  }
);

interceptor.interceptors.response.use(
  function(response) {
    loadingInstance.close();

    return response.data;
  },
  function(error) {
    if (error && error.stack.indexOf("timeout") > -1) {
      MessageBox({
        title: i18n.t("message.notice"),
        message: `
          ${i18n.t("message.chunkLoadingError1")}<a target="_blank" href="mailto:satmocks@gmail.com" target="_blank">
          ${i18n.t("message.chunkLoadingError2")}</a>${i18n.t("message.chunkLoadingError3")}
        `,
        confirmButtonText: i18n.t("alert.refresh"),
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
        dangerouslyUseHTMLString: true,
        callback: () => window.location.reload()
      });

      loadingInstance.close();
      return Promise.reject(error);
    }

    if (error.response.status === 400) {
      switch (error.response.data.message) {
        case "Unauthorized":
          Message.error("Incorrect username or password");
          break;
        case "Not found user":
          Message.error("Not found user");
          break;
        case "taken over 3 exam":
          Message.error("Taking the test too many times!");
          router.push({
            name: "SATPractices"
          });
          break;
        case "PRACTICE_EXAM_HAS_TAKEN":
          Message.error("There are already students taking the exam, so modifying the number and order of questions is not supported at the moment.");
          break;
        case "PRACTICE_EXAM_TITLE_EXISTS":
          Message.error("This test name has been used by another test.");
          break;
        case "SAT_PASSAGE_EXISTS":
          Message.error("This article has already been created, please select an existing article manually");
          break;
        default:
          Message.error("Oop, something went wrong. Please contact us.");
      }
    }
    //TODO:
    if (error.response.status === 401) {
      JwtService.destroyToken();
      store.dispatch("user/resetToken");
      if (Theme.name === "Ivy-Way") {
        if( !router.currentRoute.query.token ){
          window.location.href = "https://ivy-way.com/home?sat=sat";
        }
      } else if (Theme.name === "SAT") {
        router.push({ name: "Login" });
      }
    }

    if (error.response.status === 402) {
      let message = "";
      switch (error.response.data.message) {
        case "exceeded_test":
          message = i18n.t("toefl.planTips");
          MessageBox.confirm(message, "", {
            distinguishCancelAndClose: true,
            confirmButtonText: i18n.t("toefl.View Pro Plan"),
            cancelButtonText: i18n.t("toefl.Your Tests"),
            type: "warning"
          })
            .then(() => {
              router.replace({ name: "ToeflPlans" });
            })
            .catch(action => {
              if (action === "cancel") {
                router.replace({ name: "SatTestResults" });
              } else {
                router.go(-2);
              }
            });
          break;
        case "print_pdf_forbidden":
          message = "Not unlocked.";
          Message.error(message);
          router.push({
            name: "ToeflScorereport",
            query: router.currentRoute.query
          });
          break;
        case "point_is_not_enough":
          message = i18n.t("toefl.insufficientFunds");
          MessageBox.confirm(message, "", {
            confirmButtonText: i18n.t("alert.confirm"),
            cancelButtonText: i18n.t("alert.cancel"),
            type: "warning"
          })
            .then(() => {
              router.push({
                name: "ToeflProfileBalance",
                query: { "t-coins": error.response.data.point }
              });
            })
            .catch(() => {
              // do nothing.
            });
          break;
        default:
      }
    }

    if (error.response.status === 402 || error.response.status === 403) {
      // Message.error(error.response.data.message);
      switch (error.response.data.message) {
        case "insufficient_coins":
          MessageBox({
            title: i18n.t("message.notice"),
            message: "You can't do tests for this class at the moment. Please contact your teacher immediately.",
            confirmButtonText: "OK",
            showClose: true,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            closeOnHashChange: false,
            dangerouslyUseHTMLString: true
          });
          break;
        // default:
        //   router.replace({
        //     name: "PageRole"
        //   });
      }
    }

    if (error.response.status === 404) {
      router.push({
        name: "PageNotFound"
      });
    }

    if (error.response.status === 422) {
      let message = "";
      for (let key in error.response.data.errors) {
        message += error.response.data.errors[key];
      }
      Message.error(message);
    }
    if (error.response.status === 429) {
      switch (error.response.data.message) {
        case "Too Many Attempts.":
          MessageBox.alert(
            "Too many test papers were collected in a short period of time. Please wait and collect them again.",
            i18n.t("message.notice"),
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: i18n.t("message.continue"),
              callback: () => {
                router.go(-1);
              }
            }
          );
          break;
      }
    }

    loadingInstance.close();

    return Promise.reject(error);
  }
);

export default interceptor;
