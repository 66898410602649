const initMathJaxConfig = () => {
  if (!window.MathJax) {
    return;
  }
  window.MathJax.Hub.Config({
    showProcessingMessages: false,
    messageStyle: "none",
    jax: ["input/TeX", "output/HTML-CSS"],
    tex2jax: {
      inlineMath: [["$", "$"], ["\\(", "\\)"]],
      displayMath: [["$$", "$$"], ["\\[", "\\]"]],
      skipTags: ["script", "noscript", "style", "textarea", "pre"]
    },
    "HTML-CSS": {
      availableFonts: ["STIX", "TeX"],
      showMathMenu: false
    }
  });
};
const MathQueue = function(elements) {
  if (!window.MathJax) {
    return;
  }
  elements.forEach(element => {
    if(element){
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, element]);
    }
  });
};

const loadMathJaxScript = (callback) => {
  const plugin = document.createElement("script");
  plugin.setAttribute(
    "src",
    "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML"
  );
  plugin.async = true;
  document.head.appendChild(plugin);
  plugin.onload = () => {
    callback();
  };
};

export default {
  initMathJaxConfig,
  MathQueue,
  loadMathJaxScript
};
