import ApiService from "@/common/api.service";

export default {
  sendRestLink(data) {
    return ApiService.post("/auth/request-reset-password", data);
  },
  reset(data) {
    return ApiService.post("/auth/reset-password", data);
  },
  getPasswordUrl(data) {
    return ApiService.post("set-url", data);
  }
};
