<template>
  <div id="header" class="header" style="font-size:14px;">
    <!-- Header First -->
    <!-- <div :class="[onTop ? 'topLineBeforeScroll' : 'topLineAfterScroll']"> -->
    <transition name="fade">
      <div class="topLineBeforeScroll" v-show="onTop">
        <div>
          <!-- contact -->
          <div class="d-flex">
            <!-- Email -->
            <div class="d-flex mr-2">
              <el-tooltip
                :enterable="false"
                :content="$t('landingPage.contact.email')"
                placement="top"
                popper-class="tooltipColor"
              >
                <div class="d-flex">
                  <div class="input-icon icon-email"></div>
                  <img
                    v-if="isoCodeIsCn"
                    style="height:16px"
                    src="https://ivy-way.s3.ap-northeast-1.amazonaws.com/images/ivy-way_china_email.png"
                    alt=""
                  />
                  <img
                    v-else
                    style="height:22px;"
                    src="https://ivy-way.s3.ap-northeast-1.amazonaws.com/images/ivy-way_email.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
            </div>

            <!-- Phone -->
            <div class="d-flex mr-2">
              <el-tooltip
                class="item"
                :enterable="false"
                :content="$t('landingPage.contact.phone')"
                placement="top"
                popper-class="tooltipColor"
              >
                <div class="d-flex">
                  <div class="input-icon icon-mobile"></div>
                  <div v-if="isoCodeIsCn" style="cursor: pointer">
                    173-2124-7147
                  </div>
                  <div v-else style="cursor: pointer">
                    02-23705397 | 0978-038-334
                  </div>
                </div>
              </el-tooltip>
            </div>

            <!-- facebook -->
            <div v-if="!isoCodeIsCn" class="d-flex mr-2">
              <div>
                <el-popover placement="top" trigger="hover">
                  <div class="input-icon icon-facebook" slot="reference"></div>
                  <a href="https://facebook.com/ivywayacademy">
                    <img width="140" height="140" :src="facebookQRcode" />
                  </a>
                </el-popover>
              </div>
              <a
                style="text-decoration: none"
                href="https://facebook.com/ivywayacademy"
              >
                <div style="color: #212529">ivywayacademy</div>
              </a>
            </div>

            <!-- IG -->
            <div v-if="!isoCodeIsCn" class="d-flex mr-2">
              <div>
                <el-popover placement="top" trigger="hover">
                  <div slot="reference">
                    <span style="margin-right: 8px; font-size: 16px">
                      <i class="fab fa-instagram" />
                    </span>
                  </div>
                  <a
                    href="https://www.instagram.com/ivywayacademy/"
                  >
                    <img width="140" height="140" :src="igQRcode" />
                  </a>
                </el-popover>
              </div>
              <a
                style="text-decoration: none"
                href="https://www.instagram.com/ivywayacademy/"
              >
                <div style="color: #212529">ivywayacademy</div>
              </a>
            </div>

            <!-- line -->
            <div v-if="!isoCodeIsCn" class="d-flex mr-2">
              <div>
                <el-popover placement="top" trigger="hover">
                  <div class="input-icon icon-line" slot="reference"></div>
                  <a href="https://page.line.me/ivyway">
                    <img width="140" height="140" :src="lineQRcode" />
                  </a>
                </el-popover>
              </div>
              <a
                style="text-decoration: none"
                href="https://page.line.me/ivyway"
              >
                <div style="color: #212529">@ivyway</div>
              </a>
            </div>

            <div class="d-flex mr-2">
              <div>
                <el-popover placement="top" trigger="hover">
                  <div class="input-icon icon-wechat" slot="reference"></div>
                  <div class="d-flex">
                    <div v-if="isoCodeIsCn" class="d-block">
                      <div style="text-align: center">
                        {{ $t("landingPage.contact.shanghai") }}
                      </div>
                      <div>
                        <img width="140" height="140" :src="wechatQRcode1" />
                      </div>
                    </div>
                    <div v-if="!isoCodeIsCn" class="d-block">
                      <div style="text-align: center">
                        {{ $t("landingPage.contact.taiwan") }}
                      </div>
                      <div>
                        <img width="140" height="140" :src="wechatQRcode2" />
                      </div>
                    </div>
                    <div v-if="isoCodeIsCn" class="d-block">
                      <div style="text-align: center">
                        {{ $t("landingPage.contact.official_no") }}
                      </div>
                      <div>
                        <img width="140" height="140" :src="wechatQRcode3" />
                      </div>
                    </div>
                  </div>
                </el-popover>
              </div>
              <div>{{ isoCodeIsCn ? "ivywaychina" : "ivywaysat" }}</div>
            </div>

            <!-- dropdown for language -->
            <div class="d-flex ml-auto">
              <div class="dropdown pr-2">
                <div class="dropdown-toggle langOnTop" data-toggle="dropdown">
                  Language: {{ lang.title }}
                </div>
                <div
                  class="dropdown-menu"
                  style="z-index:2001; font-size:14px;"
                >
                  <div style="cursor: pointer">
                    <div
                      class="dropdown-item"
                      @click="handleLanguageChange(languageList1[0])"
                    >
                      {{ languageList1[0].title }}
                    </div>
                    <div
                      class="dropdown-item"
                      @click="handleLanguageChange(languageList1[1])"
                    >
                      {{ languageList1[1].title }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="pl-2">
                <el-button
                  size="mini"
                  type="success"
                  @click="goToRoute('https://ivy-way.com/enroll')"
                  >{{ $t("landingPage.enroll.btn") }}</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Header Second -->
    <div
      :class="[
        'navWrapperBefore',
        onTop ? 'menuBeforeScroll' : 'menuAfterScroll'
      ]"
    >
      <a href="https://ivy-way.com/">
        <div class="d-md-inline-block pl-4" @click="closeClick">
          <el-image
            src="https://ivy-way.s3.ap-northeast-1.amazonaws.com/images/logo/logo.png"
            class="ivy-logo onHover"
          ></el-image>
        </div>
      </a>

      <div
        id="menu"
        :class="{ active: isActive }"
        class="d-md-inline-block float-md-right"
      >
        <el-menu
          :default-active="menuIndex"
          mode="horizontal"
          router
          text-color="var(--themeColor)"
          active-text-color="var(--themeColor)"
        >
          <el-menu-item
            class="mr-0"
            :class="`item${index + 1}`"
            v-for="(menu, index) in menuList"
            :key="index"
            :route="menu.route"
            :index="String(index + 1)"
          >
            <a style="text-decoration: none" :href="menu.route">
              <div
                :class="{ menuPadding: !isLogin }"
                class="onHover pa-0 pr-1"
                @click="closeClick"
              >
                {{ $t(`header.${menu.text}`) }}
              </div>
            </a>
          </el-menu-item>

          <!-- user dropdown -->
          <el-submenu index="6-1" v-if="isLogin" class="userSection">
            <template slot="title">
              <!-- <el-button size="small" type="success"> -->
              <span v-if="getProfile.first_name"
                >Hi, {{ getProfile.first_name }}</span
              >
              <span v-else>Hi, {{ getProfile.username }}</span>
              <!-- </el-button> -->
            </template>
            <el-menu-item
              v-for="(elem, index) in loginList"
              :key="index"
              @click="goToRoute(elem.routeName)"
            >
              <p class="menuItem">{{ $t(elem.title) }}</p>
            </el-menu-item>
            <el-menu-item @click="handleLogout">
              <p class="menuItem">{{ $t("header.logout") }}</p>
            </el-menu-item>
          </el-submenu>

          <el-menu-item v-if="!isLogin" @click="goToRoute('Login')">
            <el-button size="small" type="success">{{
              $t("header.login")
            }}</el-button>
          </el-menu-item>

          <!-- Free Consulation -->
          <el-menu-item>
            <el-button
              size="small"
              type="success"
              @click="goToRoute('https://ivy-way.com/#/contact')"
              >{{ $t("landingPage.banner.btn") }}</el-button
            >
          </el-menu-item>
        </el-menu>
      </div>
      <div id="toggle" @click="toggleClick" class="mr-4">
        <div class="span" id="top" :class="{ active: isActive }"></div>
        <div class="span" id="middle" :class="{ active: isActive }"></div>
        <div class="span" id="bottom" :class="{ active: isActive }"></div>
      </div>
    </div>
    <el-drawer
      :visible.sync="isActive"
      direction="rtl"
      :modal="false"
      size="360px"
    >
      <h4 slot="title">
        <template v-if="isLogin">
          <span v-if="getProfile.first_name">
            Hi, {{ getProfile.first_name }} </span
          >
          
          <span v-else>Hi, {{ getProfile.username }}</span>
        </template>
      </h4>
      <div class="accordion listParent" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne" v-if="isLogin">
            <h2
              class="mb-0"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-controls="collapseOne"
              @click="() => toggleCollapse('collapseOne')"
            >
              <div class="collapse-item">
                <div>
                  <span style="display:inline-block;width:25px">
                    <i class="fas fa-user"></i>
                  </span>
                  Account
                </div>
                <small
                  v-if="isShow.collapseOne"
                  key="up"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-controls="collapseOne"
                >
                  <i class="fas fa-chevron-up" />
                </small>
                <small
                  v-else
                  key="down"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-controls="collapseOne"
                >
                  <i class="fas fa-chevron-down" />
                </small>
              </div>
            </h2>
          </div>

          <div
            class="card-header"
            id="headingOne"
            v-if="!isLogin"
            @click="goToRoute('Login')"
          >
            <h2
              class="mb-0"
              data-toggle="collapse"
              data-target="#collapseZero"
              aria-expanded="true"
              aria-controls="collapseZero"
            >
              <div class="collapse-item">
                <div>
                  <span style="display:inline-block;width:25px">
                    <i class="fas fa-sign-in-alt"></i>
                  </span>
                  <span>{{ $t("header.login") }}</span>
                </div>
              </div>
            </h2>
          </div>
          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body" v-if="isLogin">
              <div
                class="list onHover"
                v-for="(elem, index) in loginList"
                :key="index"
                @click="closeClick"
              >
                <router-link class="listText" :to="{ name: elem.routeName }">
                  <span style="display:inline-block;width:25px">
                    <i :class="elem.icon"></i>
                  </span>
                  {{ $t(elem.title) }}
                </router-link>
              </div>
              <div class="list onHover">
                <span class="listText" @click="handleLogout">
                  <span style="display:inline-block;width:25px">
                    <i class="fas fa-sign-out-alt"></i>
                  </span>
                  {{ $t("header.logout") }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h2
              class="mb-0"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
              @click="() => toggleCollapse('collapseTwo')"
            >
              <div class="collapse-item">
                <div>
                  <span style="display:inline-block;width:25px">
                    <i class="fas fa-book"></i>
                  </span>
                  IVY-WAY
                </div>
                <small
                  v-if="isShow.collapseTwo"
                  key="up"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <i class="fas fa-chevron-up" />
                </small>
                <small
                  v-else
                  key="down"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  <i class="fas fa-chevron-down" />
                </small>
              </div>
            </h2>
          </div>
          <div
            id="collapseTwo"
            class="collapse show"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div
                class="list onHover"
                v-for="(menu, index) in menuList"
                :key="index"
                @click="closeClick"
              >
                <router-link style="text-decoration: none" :to="menu.route">
                  <div
                    :class="{ menuPadding: !isLogin }"
                    class="onHover pa-0 pr-1"
                  >
                    <span style="display:inline-block;width:25px">
                      <i :class="menu.icon"></i>
                    </span>
                    {{ $t(`header.${menu.text}`) }}
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2
              class="mb-0"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
              @click="() => toggleCollapse('collapseThree')"
            >
              <div class="collapse-item">
                <div>
                  <span style="display:inline-block;width:25px">
                    <i class="fas fa-globe"></i>
                  </span>
                  Language
                  <span>: {{ lang.title }}</span>
                </div>
                <small
                  v-if="isShow.collapseThree"
                  key="up"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <i class="fas fa-chevron-up" />
                </small>
                <small
                  v-else
                  key="down"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <i class="fas fa-chevron-down" />
                </small>
              </div>
            </h2>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div
                class="list onHover"
                v-for="(language, index) in languageList1"
                :key="index"
              >
                <span
                  class="listText"
                  @click="handleLanguageChange(language)"
                  >{{ language.title }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="card text-center" style="padding:0 15px">
          <div class="pt-3">
            <a href="/enroll" @click="closeClick">
              <el-button plain size="small" type="success">
                {{ $t("landingPage.enroll.btn") }}
              </el-button>
            </a>
          </div>
          <div class="pt-3">
            <a href="/contact" @click="closeClick">
              <el-button plain size="small" type="success">
                {{ $t("landingPage.banner.btn") }}
              </el-button>
            </a>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import userApi from "@/apis/user";

export default {
  name: "Header",

  mixins: [],

  props: [],
  data() {
    return {
      menuIndex: "0",
      menuList: [
        {
          route: "https://ivy-way.com/about",
          text: "about",
          icon: "fas fa-info-circle"
        },
        {
          route: "https://ivy-way.com/instructors",
          text: "instructors",
          icon: "fas fa-user-tie"
        },
        {
          route: "https://ivy-way.com/classes",
          text: "classes",
          icon: "fas fa-chalkboard-teacher"
        },
        {
          route: "https://ivy-way.com/stories",
          text: "stories",
          icon: "fas fa-user-graduate"
        },
        {
          route: "https://ivy-way.com/blogs",
          text: "blogs",
          icon: "fas fa-newspaper"
        }
      ],

      languageList: [
        {
          value: "ZH-CN",
          title: "简体中文"
        },
        {
          value: "ZH-TW",
          title: "繁體中文"
        },
        {
          value: "EN-US",
          title: "English"
        }
      ],
      loginList: [
        {
          title: "header.home",
          routeName: "https://ivy-way.com/home/",
          icon: "fas fa-home"
        },
        {
          title: "header.profile",
          routeName: "https://ivy-way.com/profile",
          icon: "fas fa-id-badge"
        }
      ],
      languageList1: this.languageList,
      languageList2: this.languageList,
      isActive: false,
      windowWidth: window.innerWidth,
      onTop: true,
      wechatQRcode1: require("@/assets/qrcode/ivywaychina_QR.webp"),
      wechatQRcode2: require("@/assets/qrcode/TW_WeChat_QR.webp"),
      wechatQRcode3: require("@/assets/qrcode/ivywayedu_QR.webp"),
      facebookQRcode: require("@/assets/qrcode/ivywayfacebook_QR.webp"),
      igQRcode: require("@/assets/qrcode/ivywayIG_QR.jpg"),
      lineQRcode: require("@/assets/qrcode/line_QR.jpg"),
      currCountryCode: "US",
      headerMenuRouters: [
        "About",
        "Instructors",
        "ClassesAndCounseling",
        "Stories"
      ],
      otherMenuRouters: [
        "LandingPage",
        "LandingPageContact",
        "Home",
        "Profile"
      ],
      isShow: {
        collapseOne: false,
        collapseTwo: true,
        collapseThree: false
      }
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile"]),

    isLogin() {
      // TODO: 應該還要檢查 token 是否合法？
      return this.token !== "";
    },
    isHuge() {
      return this.windowWidth > 992;
    },
    isoCodeIsCn() {
      return false;
    }
  },
  watch: {
    // 如果 route 有變化，則讓特定 router 的情況下，menu-item 顏色全部變回 default
    $route(newVal) {
      if (this.otherMenuRouters.includes(newVal.name)) {
        this.setAllItemsToDefault();
      }
    }
  },

  async created() {
    this.updateLangOrder(this.lang.value);
    // // 為了 header 能顯示 user first_name，這邊呼叫 API
    if (this.isLogin && !this.getProfile.length) {
      await this.$store.dispatch("user/getProfile");
    }
    this.startObserveWindowWidth();
  },

  async mounted() {},

  methods: {
    ...mapActions("user", ["changeLang", "logout", "ivyWayLogin"]),
    async autoLang() {
      const { iso_code } = await this.$store.dispatch("ipGeo/getGeoLocation");
      this.currCountryCode = iso_code;
      this.changeLangDefault();
    },
    // menu-item 選擇之後會呼叫此 method
    selectItme(index) {
      this.setAllItemsToDefault();
      if (index !== null) {
        const currentClassName = "item" + index;
        const block = document.getElementsByClassName(currentClassName);
        if (block[0]) {
          block[0].style.color = "#757575";
        }
      }
    },

    // 讓所有 menu-item 的顏色變回 default
    setAllItemsToDefault() {
      for (let i = 1; i <= this.headerMenuRouters.length; i++) {
        const currentClassName = "item" + i.toString();
        const block = document.getElementsByClassName(currentClassName);
        block[0].style.color = "var(--themeColor)";
      }
    },

    stick() {
      const header = document.querySelector("header");
      if (header) {
        const condition = header.offsetHeight;
        if (window.scrollY >= condition) {
          this.onTop = false;
        } else {
          this.onTop = true;
        }
      }
    },

    // 從 this.currCountryCode 來決定使用者進入我們網站的語言
    changeLangDefault() {
      let language = {};
      if (this.currCountryCode === "TW") {
        this.$i18n.locale = "tw";
        language = this.languageList.find(x => x.value === "ZH-TW");
      } else if (this.currCountryCode === "CN") {
        this.$i18n.locale = "cn";
        language = this.languageList.find(x => x.value === "ZH-CN");
      } else {
        this.$i18n.locale = "en";
        language = this.languageList.find(x => x.value === "EN-US");
      }
      // this.updateLangOrder(language.value);
      this.changeLang(language);
    },

    async handleLanguageChange(language) {
      this.closeClick();
      this.updateLangOrder(language.value);
      let lang = "";
      switch (language.value) {
        case "ZH-TW":
          this.$i18n.locale = "tw";
          lang = "tw";
          break;
        case "EN-US":
          this.$i18n.locale = "en";
          lang = "en";
          break;
        case "ZH-CN":
          this.$i18n.locale = "cn";
          lang = "cn";
          break;
      }
      this.changeLang(language);
    },

    goToRoute(href) {
      this.closeClick();
      window.location.href = href;
    },

    goToLandingPageConsulation(route) {
      this.closeClick();
      if (this.$route.params.id !== "contact") {
        this.$router.push({
          name: route,
          params: {
            id: "contact"
          }
        });
      }
    },

    async handleLogout() {
      this.closeClick();
      await this.logout();

      await this.$message({
        message: this.$t("message.logout_success"),
        type: "success"
      });
      if (this.$route.name === "LandingPage") {
        return;
      }
      // this.$router.push({
      //   name: "LandingPage"
      // });
      window.location.href = "https://ivy-way.com/home";
    },
    closeClick() {
      document.documentElement.style.overflow = "overlay";
      this.isActive = false;
    },
    toggleClick() {
      const currOverflow = document.documentElement.style.overflow;
      if (currOverflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
      this.isActive = !this.isActive;
    },
    updateLangOrder(curLang) {
      this.languageList1 = [];
      this.languageList2 = [];
      const currIndex = this.languageList.findIndex(x => x.value === curLang);
      if (currIndex === 0) {
        this.languageList1.push(this.languageList[1]);
        this.languageList1.push(this.languageList[2]);
        this.languageList2.push(this.languageList[1]);
        this.languageList2.push(this.languageList[0]);
        this.languageList2.push(this.languageList[2]);
      } else if (currIndex === 1) {
        this.languageList1.push(this.languageList[0]);
        this.languageList1.push(this.languageList[2]);
        this.languageList2 = this.languageList;
      } else {
        this.languageList1.push(this.languageList[0]);
        this.languageList1.push(this.languageList[1]);
        this.languageList2.push(this.languageList[0]);
        this.languageList2.push(this.languageList[2]);
        this.languageList2.push(this.languageList[1]);
      }
    },
    closeMobileMenu(e) {
      if (e.target.screen.width > 992) {
        this.isActive = false;
      }
    },
    startObserveWindowWidth() {
      window.addEventListener("resize", this.closeMobileMenu);
    },
    toggleCollapse(collapse) {
      let workIsShow = { ...this.isShow };
      Object.keys(this.isShow).forEach(key => {
        workIsShow[key] = false;
      });
      this.isShow = {
        ...workIsShow,
        [collapse]: !this.isShow[collapse]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/navbar.scss";

.topLineBeforeScroll {
  width: 100%;
  top: 0;
  z-index: 2000;
  background-color: #eeeeee;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.topLineAfterScroll {
  display: none;
}

.menuBeforeScroll {
  margin-top: 40px;
  border-bottom: 1px solid #e6e9ed;
}

.menuAfterScroll {
  margin-top: 0px;
}

@media screen and (max-width: 992px) {
  .topLineBeforeScroll {
    display: none;
  }
  .menuBeforeScroll {
    margin-top: 0px;
  }
}

.el-button {
  outline: none;
}

.menuPadding {
  padding-right: 15px;
}

::v-deep .userSection .el-submenu__title {
  padding-right: 0px;
  padding-left: 40px;
}

.userButton {
  font-size: 16px;
}

.ivy-logo {
  width: 120px;
  height: 40px;
  margin: 10px 0;
  cursor: pointer;
}

.el-col {
  width: auto;
}

.el-menu {
  border-bottom: 0 !important;

  .el-menu-item {
    padding: 0 15px;
    padding-right: 20px;
  }
}
.onHover {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  font-size: 16px;
  font-weight: 700;
}
.onHover:hover {
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;
}
.fade-enter-active {
  transition: opacity 0.8s;
}
.fade-leave-active {
  opacity: 0;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.listParent {
  width: 100%;
  height: 100%;
  display: block;
}

.list {
  padding-top: 5px;
  height: 40px;
  color: var(--themeColor);
}

.listText {
  cursor: pointer;
}

.collapse-item {
  font-size: 20px;
  color: #616161;
  cursor: pointer;
}

.collapse-item:hover {
  opacity: 0.5;
}

.input-icon {
  width: 18px;
  height: 18px;
  margin-right: 3px;
  background-color: #212121;
  cursor: pointer;
}

.icon-email {
  mask: url("../assets/svg/email.svg") no-repeat center/contain;
}

.icon-mobile {
  mask: url("../assets/svg/cellphone-iphone.svg") no-repeat center/contain;
}

.icon-facebook {
  mask: url("../assets/svg/facebook.svg") no-repeat center/contain;
}

.icon-line {
  mask: url("../assets/svg/line-brands.svg") no-repeat center/contain;
}

.icon-wechat {
  mask: url("../assets/svg/wechat.svg") no-repeat center/contain;
}

::v-deep .menuTop .el-submenu__title {
  margin: 0px;
  padding: 0px;
  max-height: 30px;
}

::v-deep .menuTop .el-submenu__title p {
  margin: 0px;
  padding: 0px;
  max-height: 30px;
}

.langOnTop {
  color: #212121;
  opacity: 1;
}

.langOnTop:hover {
  color: var(--themeColor);
  opacity: 0.8;
  cursor: pointer;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.el-button--mini {
  padding: 5px 15px;
}

.el-button--default {
  padding: 0px;
  padding-bottom: 5px;
}

.card {
  border: 0px;
  font-size: 18px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-header {
  background-color: white;
  border: 0px;
}

.collapse-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.card-body {
  padding: 5px 5px 5px 35px;
}

.menuItem {
  color: black;
}

.menuItem:hover {
  color: rgba(0, 0, 0, 0.4);
}

.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}

::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
  opacity: 0;
}
</style>
